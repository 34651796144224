.bpm-workflow-legend {
    position: absolute;
    bottom: 2rem;
    border: 1px solid #e1e1e1;
    left: 0;
    right: 0;
    width: 30rem;
    max-width: 93%;
    margin: auto;
    text-align: center;
    background: white;
}
.bpm-workflow-legend small {
    color:grey;
}

.bpm-workflow-legend ul {
    list-style-type: none;
    margin: 0;
    padding: 1px 1px;
}

.bpm-workflow-legend ul li {
    margin: 10px;
    display: inline-block;
}

.bpm-workflow-legend ul li div {
    width: 10px;
    height: 10px;
    display: inline-block;
}

.bpm-workflow-legend .bpm-workflow-legend-failed {
    background-color: #DE322F;
}

.bpm-workflow-legend .bpm-workflow-legend-completed {
    background-color: #2BD62E;
}

.bpm-workflow-legend .bpm-workflow-legend-pending {
    background-color: #DDDDDD;
}

.bpm-workflow-legend .bpm-workflow-legend-active {
    background-color: #53C9ED;
}

.bpm-workflow-legend .bpm-workflow-legend-rollback {
    background-color: #ffd27f;
}

.bpm-table-scroll {
    height: 840px;
    overflow-y: scroll;
}

.react-flow__pane {
    height: 90%;
}

.react-flow__nodes {
    resize: both;
    overflow: auto;
}